import React, { useState, useEffect } from 'react';
import { MathComponent } from "mathjax-react";
import './AssignmentList.scss'
import AssignmentCards from '../../components/Assignment/AssignmentCards';
import { getAssignmentList, updateassignment, getassignmenttitle, getcoursesbyinstituteid, fetchassignmentchallengegrades, updateassignmentchallengepoints } from '../../common/API'
import CircularProgress from '@mui/material/CircularProgress';
import { user_plan, getuserAccess, getCurrentUserId, detectDevice, TABLET, DESKTOP, getMenuSelectedItem, getCurrentUserRole, User_Role, showFullApp, isDemoUser, getUserFingerprint, getInstituteId } from '../../common/Functions';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import infoIcon from '../../assets/images/info_icon.png'
import Modal from '../../components/Common/ModalComponent/ModalComponent';
import whiteBoardVideo from "../../assets/videos/Quiz_HelpCenter.mp4";
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { isNil, isEmpty } from 'lodash';
import AssignmentChallengeListItem from './AssignmentChallengeListItem';
import configData from "../../common/config.json";
import { Institute } from '../../common/Constants';
import DialogTitle from '@mui/material/DialogTitle';
import Table from 'react-bootstrap/Table';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import MathJaxRenderer from '../../components/Common/MathJaxRenderer/MathJaxRenderer';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    maignGrid: {
        marginLeft: "0px"
    },
}));

const AssignmentList = () => {
    const classes = useStyles()
    const [assignmentData, setassignmentData] = useState([])
    const [loader, setLoader] = useState(false)
    const [whiteBoardVideoDiv, setwhiteBoardVideoDiv] = useState("")
    const [updateList, setUpdateList] = useState(false)
    const [showModalVideoUsageGuide, setshowModalVideoUsageGuide] = useState(false)
    const [displayMessage, setdisplayMessage] = useState("Fetching Data...")
    const [homewrokTitleData, sethomewrokTitleData] = useState([])
    const [showGuideanceMessage, setshowGuideanceMessage] = useState(false)
    const [generalInstructions, setgeneralInstructions] = useState("")
    const [valuestart, setvaluestart] = useState(new Date())
    const [valueend, setvalueend] = useState(new Date())
    const [error, seterror] = useState("")
    const [enderror, setenderror] = useState("")
    const [title, settitle] = useState("")
    const [graded, setgraded] = useState(false)
    const [description, setdescription] = useState("")
    const [titleDisabled, settitleDisabled] = useState(false)
    const [assignmentId, setassignmentId] = useState(0)
    const [timed, settimed] = useState(false)
    const [guided, setguided] = useState(true)
    const [totalMinutes, settotalMinutes] = useState(15)
    const [nooftrials, setnooftrials] = useState(1)
    const [assignmentType, setassignmentType] = useState("")
    const [errorTextGraded, seterrorTextGraded] = useState("")
    const [errorTextTimed, seterrorTextTimed] = useState("")
    const [opencreatehomeworkmodal, setopencreatehomeworkmodal] = useState(false)
    const [OpenDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
    const [titleduplicateMessage, settitleduplicateMessage] = useState(false)
    const [titlerequiredMessage, settitlerequiredMessage] = useState(false)
    const [ChallengeData, setChallengeData] = useState([])
    const [deletedChallengeId, setdeletedChallengeId] = useState([])
    const [isLastchallenge, setisLastchallenge] = useState(false)
    const [openPointModal, setopenPointModal] = useState(false)
    const [challengePointsdata, setchallengePointsdata] = useState([])
    const [pointDataMessage, setpointDataMessage] = useState("")
    const [selectedChallengeGrade, setselectedChallengeGrade] = useState("")
    const [selectedChallengeType, setselectedChallengeType] = useState("")
    const [selectedAssignmentTitle, setselectedAssignmentTitle] = useState("")

    useEffect(() => {
        if (isDemoUser() !== 'true') {
            if (getMenuSelectedItem() !== undefined && getMenuSelectedItem() !== null && getMenuSelectedItem() !== "") {
                if (getCurrentUserRole() !== User_Role.Student) {

                    const reqData = { params: { "user_id": getCurrentUserId(), "course_id": getMenuSelectedItem() } };
                    getassignmenttitle(reqData)
                        .then(res => {
                            if (res.data.data !== undefined && res.data.data !== null && res.data.data.length > 0) {
                                // let filterdata = res.data.data.filter(e=> e.value.toString() !== ) 
                                //   setshowGuideanceMessage(true)
                                sethomewrokTitleData(res.data.data)
                            }
                        })
                        .catch(err => {
                            console.error(err.message)
                            setLoader(false)
                        })
                }
                getAssignmentDetails(getMenuSelectedItem())
            }
            else {
                const reqData = { params: { "user_id": getCurrentUserId() } };
                getcoursesbyinstituteid(reqData)
                    .then(res => {
                        debugger;
                        if (res.data.data !== undefined && res.data.data !== null && res.data.data.length > 0) {
                            const reqData = { params: { "user_id": getCurrentUserId(), "course_id": res.data.data[0].value } };
                            getassignmenttitle(reqData)
                                .then(res => {
                                    if (res.data.data !== undefined && res.data.data !== null && res.data.data.length > 0) {
                                        sethomewrokTitleData(res.data.data)
                                    }
                                })
                                .catch(err => {
                                    console.error(err.message)
                                    setLoader(false)
                                })

                            //  setshowGuideanceMessage(true)
                            getAssignmentDetails(res.data.data[0].value)
                        }
                    })
                    .catch(err => {
                        console.error(err.message)
                        setLoader(false)
                    })
            }
        }
    }, []);

    const getAssignmentDetails = (courseid) => {
        setLoader(true)
        let limitedaccess = 0
        let aplan = getuserAccess()
        if (aplan !== undefined && aplan !== null && aplan !== "") {
            if (aplan === user_plan.limitedaccess) {
                limitedaccess = 1
            }
        }
        const reqData = { params: { 'user_id': getCurrentUserId(), "course_id": courseid, "limitedaccess": limitedaccess } }
        getAssignmentList(reqData).then(res => {
            if (!isNil(res.data) && !isEmpty(res.data)) {
                setassignmentData(res.data)
                setLoader(false)
            }
            else {
                setLoader(false)
                setassignmentData([])
                setdisplayMessage("No Data Found.")
            }
        }).catch(err => {
            setLoader(false)
            console.error(err.message);
        })
    }

    useEffect(() => {
        if (updateList) {
            getAssignmentDetails(getMenuSelectedItem())
        }
    }, [updateList]);

    const itemchangedhandler = (event) => {
        setUpdateList(true)
    }

    const usageguidehandler = () => {
        setshowModalVideoUsageGuide(true)
        setwhiteBoardVideoDiv(
            <div className='videoModalDiv'>
                <div className='videoDiv'>
                    <video playsinline muted loop={true} autoPlay={true} width="590" height="270" controls>
                        <source src={whiteBoardVideo} type="video/mp4" />
                    </video>
                </div>
                <div className='videoDescription mt-4'>
                    <p>Welcome to the Quiz Guide! In this video, we will walk you through everything you need to know to successfully complete your quiz.</p>
                </div>
                <div className='modal-button text-center'>
                    <Button className="videoClosebtn" onClick={closeusagaeguidehandler}> CLOSE </Button>
                </div>
            </div>
        )
    }

    const closeusagaeguidehandler = () => {
        setshowModalVideoUsageGuide(false)
    }

    const closeGuideanceMessagehandler = () => {
        setshowGuideanceMessage(false)
    }

    const updateAssignment = (flag) => {
        const reqData = {
            start_date: dayjs(new Date(valuestart)),
            end_date: dayjs(new Date(valueend)),
            description: description,
            // user_id: getCurrentUserId(),
            title: title.trim(),
            total_trials: guided && graded ? 1000 : nooftrials, /// with Ref. to khyati, if assignment is guided and graded, we will allow student to submit multiple times and "Check Score" btn is set to visisble false
            assignment_type: assignmentType,
            isgraded: graded,
            isguided: guided,
            istimed: timed,
            assignment_time: totalMinutes,
            general_instructions: generalInstructions,
            course_id: getMenuSelectedItem(),
            "id": parseInt(assignmentId),
            "flag": flag,
            "deletetedChallengeIds": deletedChallengeId.length > 0 ? deletedChallengeId : ""
        }
        updateassignment(reqData).then(res => {
            if (res.data !== undefined && res.data !== null) {
                setOpenDeleteConfirmation(false)
                getAssignmentDetails(getMenuSelectedItem())
                setopencreatehomeworkmodal(false)
            }
        })
    }

    const handleupdateAssignment = (e, flag) => {
        if (flag === "edit") {
            if (homewrokTitleData !== undefined && homewrokTitleData !== null && homewrokTitleData.length > 0) {
                let titleexistindropdown = homewrokTitleData.filter(e => e.key.toLowerCase() === title.toLowerCase().trim() && parseInt(e.value) !== parseInt(assignmentId))
                if (titleexistindropdown !== undefined && titleexistindropdown !== null && titleexistindropdown.length > 0) {
                    settitleduplicateMessage(true)
                }
                else {
                    if (title === "" || title === null || title === undefined) {
                        settitlerequiredMessage(true)

                    }
                    else {
                        if (enderror === "") {
                            updateAssignment(flag)
                        }
                    }
                }
            }
            else {
                updateAssignment(flag)
            }

        }
        else {
            updateAssignment(flag)
        }
    }

    const handleChangestart = (newValue) => {
        setvaluestart(new Date(newValue))
        const today = dayjs(new Date(newValue));
        const pastDate = dayjs(new Date(valueend));
        if (today.isAfter(pastDate)) {
            return setenderror("End date must be greater then start date!")
        }
        else {
            setenderror("")
        }

    };
    const handleChangeend = (newValue) => {
        setvalueend(new Date(newValue))
        const today = dayjs(new Date(newValue));
        const pastDate = dayjs(new Date(valuestart));
        if (pastDate.isAfter(today)) {
            return setenderror("End date must be greater then start date!")
        }
        else {
            setenderror("")
        }
    };

    const handleTitleChange = (e) => {
        settitle(e.target.value)
        settitleduplicateMessage(false)

    }

    const handleTotalMinutesChange = (e) => {
        settotalMinutes(e.target.value)
        if (e.target.value <= 0 && timed) {
            seterrorTextTimed("Duration is required.")
        }
        else if (e.target.value <= 15 && timed) {
            seterrorTextTimed("Duration should be atleast 15 minutes or more.")
        }
        else {
            seterrorTextTimed("")
        }
    }

    const handleNoOfTrialsChange = (e) => {
        setnooftrials(e.target.value)
        if (e.target.value <= 0 && graded) {
            seterrorTextGraded("No. of trials is required.")
        }
        else {
            seterrorTextGraded("")
        }
    }

    const handleGradedChangecheckbox = (e) => {
        setgraded(e.target.checked)
    }

    const handleGuidedChangeCheckbox = (e) => {
        setguided(e.target.checked)
    }

    const handleTimedChangeCheckbox = (e) => {
        settimed(e.target.checked)
    }

    const handleAssignmentTypeSelect = (e) => {
        setassignmentType(e.target.value)
    }

    const handledescriptionchange = (e) => {
        setdescription(e.target.value)
    }
    const handleGeneralInstruction = (e) => {
        setgeneralInstructions(e.target.value)
    }
    const onKeyDown = (e) => {
        e.preventDefault();
    };
    const clostassignmentmodal = () => {
        setopencreatehomeworkmodal(false)
    }

    const handleeditmodedata = (e, start_date, end_date
        , description, general_instructions, title, total_trials
        , assignment_type, isgraded, isguided, istimed, assignment_time, id, challengeData, assignmenttitle) => {
        setopencreatehomeworkmodal(true)
        setdescription(description)
        settitle(title)
        setgraded(Boolean(isgraded))
        setvalueend(dayjs(new Date(end_date)))
        setvaluestart(dayjs(new Date(start_date)))
        setassignmentId(id)
        setguided(Boolean(isguided))
        settimed(Boolean(istimed))
        setnooftrials(total_trials)
        settotalMinutes(assignment_time)
        setassignmentType(assignment_type)
        setgeneralInstructions(general_instructions)
        seterror("")
        setChallengeData(challengeData)
        setselectedAssignmentTitle(assignmenttitle)

        if (challengeData.length === 1) {
            setisLastchallenge(true)
        }
        else {
            setisLastchallenge(false)
        }
        let filterdata = [...homewrokTitleData]
        filterdata = filterdata.filter(e => e.value.toString() !== id.toString())
        sethomewrokTitleData(filterdata)

        const today = dayjs(new Date(end_date));
        const pastDate = dayjs(new Date(start_date));
        if (pastDate.isAfter(today)) {
            return setenderror("End date must be greater then start date!")
        }
        else {
            setenderror("")
        }
    }

    const handledeleteconfirmation = (e, assignmentId) => {
        setassignmentId(assignmentId)
        setOpenDeleteConfirmation(true)
    }

    const handledeleteconfirmationclose = () => {
        setOpenDeleteConfirmation(false)
    }
    const handledeletedchallenges = (e, challengeid) => {
        let challengeids = []
        challengeids = [...deletedChallengeId]
        challengeids.push(parseInt(challengeid.slice(1)))
        setdeletedChallengeId(challengeids)

        let challengeList = [...ChallengeData]
        let filterdata = challengeList.filter(o => o.id !== challengeid)

        if (filterdata.length === 1) {
            setisLastchallenge(true)
        }
        else {
            setisLastchallenge(false)
        }
        setChallengeData(filterdata)

    }
    const handleChallengesPointEdit = (e, challengeid, name, type) => {
        setLoader(true)
        setpointDataMessage("Fetching Data!")
        setselectedChallengeGrade(name)
        setselectedChallengeType(type)
        const reqData = { params: { 'challenge_id': challengeid, "assignment_id": assignmentId } }
        fetchassignmentchallengegrades(reqData).then(res => {
            if (!isNil(res.data) && !isEmpty(res.data)) {
                setchallengePointsdata(res.data)
                setopenPointModal(true)
                setLoader(false)
                setpointDataMessage("")

            }
            else {
                setopenPointModal(true)
                setLoader(false)
                setchallengePointsdata([])
                setpointDataMessage("No Data Found.")
            }
        }).catch(err => {
            setLoader(false)
            console.error(err.message);
            setpointDataMessage("No Data Found.")
        })
    }
    const closePointModal = () => {
        setopenPointModal(false)
    }
    const handleupdateChallengePoint = () => {
        let form_data = new FormData();
        form_data.append("user_id", getCurrentUserId())
        form_data.append("challengePointsdata", JSON.stringify(challengePointsdata))
        updateassignmentchallengepoints(form_data).then(res => {
            if (res.status === 200) {
                toast.success("Points updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                setopenPointModal(false)
                getAssignmentDetails(getMenuSelectedItem())

            }
        }).catch(err => {
            toast.error("Error!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            console.error(err.message)
            setopenPointModal(false)
        })

    }
    const handlePointchange = (e, index, id) => {

        let changeData = [...challengePointsdata]
        if (changeData[index] !== undefined) {
            let tempArr = changeData[index]
            // if (e.target.value !== "") {
            //     tempArr.points = e.target.value// parseFloat(e.target.value).toFixed(2);
            // }
            // else {
            tempArr.points = e.target.value
            // }

            changeData[index] = tempArr
            setchallengePointsdata(changeData)
        }

    }
    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                disableEnforceFocus={true}
                hideBackdrop={false}
                open={openPointModal}
                onClose={closePointModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <div style={{ fontSize: "14px" }}>{selectedChallengeGrade}</div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={closePointModal}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {
                        challengePointsdata !== undefined && challengePointsdata !== null && challengePointsdata.length > 0 ?
                            (
                                <>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                {
                                                    selectedChallengeType === "FRQ" ? <th>#</th> : null
                                                }

                                                <th>{
                                                    selectedChallengeType === "FRQ" ? "Equations" : "Answer"
                                                }</th>
                                                <th>Points</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                challengePointsdata.map((challenge, index) => {
                                                    let cpoints = ""
                                                    let srno = index + 1
                                                    // if (challenge.points !== null && challenge.points !== "" && challenge.points !== undefined) {
                                                    //     cpoints = parseFloat(challenge.points).toFixed(2)
                                                    // }
                                                    // else {
                                                    cpoints = challenge.points
                                                    // }
                                                    return (
                                                        <>
                                                            <tr>
                                                                {
                                                                    selectedChallengeType === "FRQ" ? <td>{srno}</td> : null
                                                                }

                                                                <td>
                                                                    {
                                                                        challenge.challenge_type !== "FRQ" ?
                                                                            (
                                                                                <>
                                                                                    {/* <div className='multilineAssignmentchallengeGradeequations' title={challenge.equation}>
                                                                                        <span className="ch-title">
                                                                                            {parse(challenge.equation)}
                                                                                        </span>
                                                                                    </div> */}

                                                                                    <div style={{ fontSize: "13px" }} className="eqfont modalscroll updategradetext">
                                                                                        <MathJaxRenderer math={challenge.equation}></MathJaxRenderer>

                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div><MathComponent tex={challenge.equation_latex}></MathComponent></div>

                                                                                    <div style={{ fontSize: "13px" }} className="mt-1 d-flex align-items-center eqfont modalscroll updategradetext, eqpaddingshowsolution">
                                                                                        <MathJaxRenderer math={challenge.explanation}></MathJaxRenderer>

                                                                                    </div>
                                                                                </>)

                                                                    }

                                                                </td>
                                                                <td> <input style={{ marginLeft: '5px' }} name="points" class="form-control bg-yellow" type='number'
                                                                    onChange={(e) => handlePointchange(e, index, challenge.id)}
                                                                    value={cpoints}></input>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </Table>
                                </>
                            )
                            :
                            (<>{pointDataMessage}</>)
                    }

                </DialogContent>
                <DialogActions>
                    {
                        challengePointsdata !== undefined && challengePointsdata !== null && challengePointsdata.length > 0 ?
                            (<>
                                <button className="dragLabelcancel leftmargin" onClick={handleupdateChallengePoint}>
                                    Save
                                </button>
                                <button className="dragLabelcancel leftmargin" onClick={closePointModal}>
                                    Cancel
                                </button>
                            </>) : <button className="dragLabelcancel leftmargin" onClick={closePointModal}>
                                Ok
                            </button>
                    }

                </DialogActions>
            </Dialog >
            <Dialog
                hideBackdrop={false}
                open={OpenDeleteConfirmation}
                onClose={handledeleteconfirmationclose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>
                            Are you sure you want to delete this assignment?
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="dragLabelcancel leftmargin" onClick={(e) => handleupdateAssignment(e, "delete")}>
                        Yes
                    </button>
                    <button className="dragLabelcancel leftmargin" onClick={handledeleteconfirmationclose}>
                        No
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                hideBackdrop={false}
                fullWidth
                maxWidth="md"
                open={opencreatehomeworkmodal}
                onClose={clostassignmentmodal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Assignemnt-  {selectedAssignmentTitle}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={clostassignmentmodal}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <>

                            <label style={{ fontSize: "15px", fontWeight: "600" }}>Title:</label>
                            <input class="form-control bg-yellow" value={title}
                                onChange={handleTitleChange}
                            ></input>
                            {
                                titlerequiredMessage ? (
                                    <label style={{ marginLeft: '5px', color: 'red', marginTop: "10px", fontSize: "14px" }}>
                                        Title is required. </label>
                                ) : null
                            }
                            {
                                titleduplicateMessage ? (
                                    <label style={{ marginLeft: '5px', color: 'red', marginTop: "10px", fontSize: "14px" }}>
                                        Entered title is already exists. </label>
                                ) : null
                            }
                            {/* <div className='titleorcss'></div> */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack sx={{ marginTop: "20px" }} direction="row" spacing={2} >
                                    <label style={{ fontSize: "15px", fontWeight: "600", paddingTop: "15px" }}>Start Date:</label>
                                    <DatePicker
                                        sx={{ marginTop: "0px !important", marginLeft: "5px" }}
                                        value={valuestart}

                                        onChange={handleChangestart}
                                        renderInput={(params) => <TextField {...params} />} />
                                    <label style={{ fontSize: "15px", fontWeight: "600", paddingTop: "15px" }}>End Date:</label>
                                    <DatePicker
                                        sx={{ marginTop: "0px !important", marginLeft: "5px" }}
                                        disabled={titleDisabled}
                                        value={valueend}
                                        onChange={handleChangeend}
                                        renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} error={!!enderror}
                                            helperText={enderror} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            {/* <div className='titleorcss'></div> */}
                            <label style={{ marginTop: "10px", fontSize: "15px", fontWeight: "600" }}>Description:</label>
                            <textarea
                                class="form-control bg-yellow"
                                rows="3"
                                value={description}
                                placeholder='Enter Description...'
                                style={{ width: `${showFullApp() ? "400px" : "300px"}` }}
                                disabled={titleDisabled}
                                onChange={handledescriptionchange}
                            />
                            <div className='titleorcss'></div>
                            <label style={{ marginTop: "10px", fontSize: "15px", fontWeight: "600" }}>Manage Challenges:</label>
                            <Stack direction="row" spacing={2} sx={{ width: "800px", overflowX: "auto", margin: "5px", padding: "10px" }}>
                                {
                                    ChallengeData !== undefined && ChallengeData !== null && ChallengeData.length > 0 ?
                                        (
                                            <>
                                                {
                                                    ChallengeData.map((challenge) => {
                                                        return (
                                                            <>
                                                                <AssignmentChallengeListItem
                                                                    graded={graded}
                                                                    data={challenge}
                                                                    isLastchallenge={isLastchallenge}
                                                                    deletedId={(e) => handledeletedchallenges(e, challenge.id)}
                                                                    editpointhandler={(e) => handleChallengesPointEdit(e, challenge.id, challenge.display_name, challenge.type)}
                                                                ></AssignmentChallengeListItem>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                        ) :
                                        (
                                            <>
                                                <div style={{ fontSize: "18px" }}>No Data Found!</div>
                                            </>
                                        )
                                }
                            </Stack>



                            <div className='titleorcss'></div>
                            <Stack direction="row" spacing={2} >
                                <label style={{ fontSize: "15px", fontWeight: "600", marginTop: "7px", marginBottom: "7px" }}>Guided: </label>
                                <input style={{ marginLeft: '10px', display: 'inline', marginTop: "7px", marginBottom: "7px" }}
                                    checked={guided} type="checkbox"
                                    onChange={(e) => handleGuidedChangeCheckbox(e)} disabled={false} />
                            </Stack>
                            <div className='titleorcss'></div>
                            <Stack direction="row" spacing={2} >
                                <label style={{ fontSize: "15px", fontWeight: "600", marginTop: "7px", marginBottom: "7px" }}>Graded: </label>
                                <input style={{ marginLeft: '10px', display: 'inline', marginTop: "7px", marginBottom: "7px" }}
                                    checked={graded} type="checkbox"
                                    onChange={(e) => handleGradedChangecheckbox(e)} disabled={false} />
                                {graded ?
                                    guided ? null :
                                        <>
                                            <label style={{ fontSize: "15px", fontWeight: "600", marginTop: "7px", marginBottom: "7px" }}>No. of Trials: </label>
                                            <input style={{ width: '70px', display: 'inline' }}
                                                value={nooftrials} type="number" onChange={(e) => handleNoOfTrialsChange(e)}
                                                disabled={false} />
                                            <label style={{ marginLeft: '5px', color: 'red', fontSize: "14px" }}>
                                                {errorTextGraded} </label>
                                        </>

                                    : null}
                            </Stack>
                            <div className='titleorcss'></div>
                            <Stack direction="row" spacing={2}>
                                <label style={{ fontSize: "15px", fontWeight: "600", marginTop: "7px", marginBottom: "7px" }}>Timed: </label>
                                <input style={{ marginLeft: '10px', display: 'inline', marginTop: "7px", marginBottom: "7px" }}
                                    checked={timed} type="checkbox"
                                    onChange={(e) => handleTimedChangeCheckbox(e)} disabled={false} />
                                {timed ?
                                    <>
                                        <label style={{ fontSize: "15px", fontWeight: "600", marginTop: "7px", marginBottom: "7px", marginLeft: "20px" }}>Duration: </label>
                                        <input type='number' value={totalMinutes}
                                            style={{ width: '70px', display: 'inline' }}
                                            onChange={handleTotalMinutesChange}
                                            disabled={false}></input>
                                        <label style={{ marginLeft: '5px', fontSize: "15px", fontWeight: "600", marginTop: "7px", marginBottom: "7px" }}> Minutes:</label>
                                        <label style={{ marginLeft: '5px', color: 'red', fontSize: "14px", marginTop: "7px", marginBottom: "7px" }}>{errorTextTimed} </label>
                                    </>
                                    : null}
                            </Stack>
                            <div className='titleorcss'></div>
                            <label style={{ marginTop: "10px", fontSize: "15px", fontWeight: "600" }}>Assignment Type:</label>
                            <select
                                class="form-control bg-yellow"
                                onChange={handleAssignmentTypeSelect}
                                value={assignmentType}
                                disabled={titleDisabled}
                                name="assignmentType1">
                                <option value="homework">Homework</option>
                                <option value="quiz">Quiz</option>
                            </select>
                            <div className='titleorcss'></div>
                            <label style={{ fontSize: "15px", fontWeight: "600" }}>Instructions for Student:</label>
                            <textarea
                                class="form-control bg-yellow"
                                rows="3"
                                value={generalInstructions}
                                placeholder='Enter Instructions for student...'
                                style={{ width: `${showFullApp() ? "590px" : "300px"}` }}
                                disabled={titleDisabled}
                                onChange={handleGeneralInstruction}
                            />
                        </>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className='modal-button text-center'>
                        <Button type='submit' className="yellow-btn" onClick={(e) => handleupdateAssignment(e, "edit")}> Save </Button>
                        <Button className="ml-2 grey-btn" onClick={clostassignmentmodal}> Cancel </Button>
                    </div>
                </DialogActions>
            </Dialog>


            {
                ((detectDevice() === TABLET || detectDevice() === DESKTOP) && isDemoUser() !== 'true') ?
                    <div className="APTestPaper">
                        <div className="container">
                            <div className='borderBottom'>
                                <div className="label d-flex justify-content-between">
                                    <div className="text-wrapper">{getInstituteId() === Institute.Rice || getInstituteId() === Institute.NYU ? "Assignments" : "Practice Tests"}</div>
                                    <div>
                                        <button
                                            onClick={usageguidehandler} className="btnLightBlue" style={{ marginLeft: "5px" }}>Usage Guide</button>
                                    </div>
                                </div>
                            </div>

                            {assignmentData !== undefined && assignmentData !== null && assignmentData.length > 0 ?
                                (
                                    <Box sx={{ flexGrow: 1, marginBottom: "50px", padding: "20px", maxWidth: "100%", width: '100%' }} >
                                        <Grid className={classes.maignGrid} sx={{ marginBottom: "50px" }} container spacing={{ xs: 1, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                                            {
                                                assignmentData.map((data, index) => {
                                                    return (
                                                        <Grid item xs={2} sm={4} md={4} key={index}>
                                                            <AssignmentCards
                                                                openeditmodeModal={(e) =>
                                                                    handleeditmodedata(e, data.start_date, data.end_date
                                                                        , data.description, data.general_instructions, data.title, data.total_trials
                                                                        , data.assignment_type, data.isgraded, data.isguided, data.istimed
                                                                        , data.assignment_time, data.id, data.challengeData, data.title)}
                                                                homewrokTitleData={homewrokTitleData}
                                                                handledeleteconfirmation={(e) => handledeleteconfirmation(e, data.id)}
                                                                item={data} itemchanged={(e) => itemchangedhandler(e)}></AssignmentCards></Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Box>
                                )
                                :
                                <Box sx={{ flexGrow: 1, marginBottom: "50px", padding: "20px", maxWidth: "100%", width: '100%' }}>{displayMessage} </Box>
                            }
                        </div>
                    </div>
                    :
                    <div style={{ marginTop: '100px', textAlign: 'center', background: '#fff' }}>
                        {
                            isDemoUser() !== 'true' ?
                                <>
                                    <h5>Optimized Experience Advisory</h5>
                                    <img src={infoIcon}></img>
                                    <p>We are actively working on making aiPlato available on all mobile and tablet devices. Currently we only support large screens.</p></> :
                                <>
                                    <h5>Unlock Access to Practice Tests!</h5>

                                    <p style={{ fontSize: '18px' }}>Practice Tests are available to our subscribers. To access this feature, please &nbsp;
                                        <a href={`${configData.WEBSITE_URL}signUpPlans/${getUserFingerprint()}`} target="_self" style={{ color: "blue", textDecoration: "underline" }}>
                                            subscribe
                                        </a> with your email. Enhance your learning experience with personalized test preparation!</p>
                                </>
                        }

                    </div>
            }

            {
                loader && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }

            <Modal className='modalVideo'
                show={showModalVideoUsageGuide}
                onHide={closeusagaeguidehandler}
                draggable={false}
                body={whiteBoardVideoDiv}
                handleClose={closeusagaeguidehandler}
                hideHeader={true}
                showButton={false}
            >
            </Modal>

            <Modal className='modalVideo'
                show={showGuideanceMessage}
                onHide={closeGuideanceMessagehandler}
                draggable={false}
                body={<div>
                    These practice tests are "Guided".<br />
                    Guidance will not get in your way unless you ask for guidance by clicking on "Evaluate My Work.", "Hints," etc.
                    <br />
                    <br />
                </div>}
                handleClose={closeGuideanceMessagehandler}
                hideHeader={false}
                title="Alert"
                showButton={false}
            >
            </Modal>

        </>
    )
}

export default AssignmentList