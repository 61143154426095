import React, { useEffect } from 'react';
import './TopicChallenges.scss';
import { Link, withRouter } from 'react-router-dom';
import TryThisPin from '../../Common/TryThisPin/TryThisPin';

import { getCurrentUserRole, getTryThisPinsEnabledFlag, Problem_source, showFullApp, getMenuSelectedItem } from '../../../common/Functions';
import { Row, Col, Modal } from "react-bootstrap";

import { getQATestFlag, User_Role, getCurrentUserId, Challenge_status } from '../../../common/Functions';
import Dropdown from "react-bootstrap/Dropdown";
import { imgURL } from '../../../common/API';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import parse from 'html-react-parser';
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from '@material-ui/core/ImageListItem'
import ListSubheader from '@material-ui/core/ListSubheader';
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import betaimage from '../../../assets/images/beta-11.png'
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'universal-cookie';

let selected_ids = ""
const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(3),
            width: "25ch"
        }
    },
    gridList: {
        height: "auto",
        position: "relative",
        overflowY: "unset",
        marginBottom: "10px !important",
        //columnGap: "50px",
        width: "100%",
        cursor: "auto"
        //borderBottom: "1px solid rgb(209, 209, 209)"
    },
    card: {
        // minWidth: "300px",
        // height: "370px",
        // height: "95%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        margin: "10px",
        borderRadius: "10px",
        //position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#fff",
        cursor: "auto"
    },
    cardstudent: {
        // minWidth: "300px",
        // height: "370px",
        margin: "10px",
        borderRadius: "10px",
        //position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#fff",
        cursor: "auto"
    },
    carddisabled: {
        // minWidth: "300px",
        // height: "340px",
        margin: "10px",
        borderRadius: "10px",
        //position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#f1f1f1"
    },


    contentimage: {
        padding: "5px",
        borderRadius: "12px",
        width: "100%",
        height: "210px",
        objectFit: "contain",
        cursor: "pointer !important",
        pointerEvents: "fill",
        maxWidth: '420px'
    },
    font16: {
        fontSize: "16px !important",
    },
    datepickercls: {
        marginTop: "0px !important"
    },
    contentclass: {
        padding: "10px !important"
    },
    complexityTooltip: {
        width: "180px !important",
        // width: "92px",
        height: "36px",
        borderRadius: "18px",
        boxShadow: "0 20px 80px 0",
        backgroundColor: "red"
    }

}));


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a style={{ boxShadow: "none", textDecoration: 'none' }}
        href="#" box
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <span className="threedots" />
    </a>
));


const TopicChallenges = (props) => {

    const classes = useStyles();

    const [afteraddhwfinalids, setafteraddhwfinalids] = React.useState([]);
    const [disabledChallengeMessage, setDisabledChallengeMessage] = React.useState(false)




    let challengetopicclass = 'challengeTopic'
    if (props.index === 1) {
        challengetopicclass = "challengeTopic"
    }
    let showChallenge;



    useEffect(() => {
        if (props.createAssignmentCloseorSave) {
            selected_ids = ""
        }
    }, [props.createAssignmentCloseorSave])


    const handleChangecheckbox = (e, challengeid) => {
        let checkedtruefalse = e.target.checked
        if (checkedtruefalse) {
            if (selected_ids === "") {
                selected_ids = challengeid
            }
            else {
                if (selected_ids.includes(",")) {
                    const array = selected_ids.split(',');
                    if (!array.includes(challengeid)) {
                        selected_ids = selected_ids + "," + challengeid
                    }
                }
                else {
                    selected_ids = selected_ids + "," + challengeid
                }
            }
        }
        else {
            let data1 = selected_ids
            if (data1 !== "") {
                let spiltdata = data1.split(',')
                let finalstring = ''
                spiltdata.forEach(item => {
                    if (item !== challengeid) {
                        if (finalstring === '') {
                            finalstring = item
                        }
                        else {
                            finalstring = finalstring + "," + item
                        }
                    }
                })

                selected_ids = finalstring
            }
        }
        debugger;
        props.getselectedChallengeIds(selected_ids)
    }

    const getColor = (complexity) => {
        if (complexity == "Medium")
            return 'yellow'
        else if (complexity == "Easy")
            return "green"
        else
            return 'orange';
    }
    const cardclickHandler = (e, path, id) => {
        if (path === "") {
            setDisabledChallengeMessage(true)
        }
        else {
            setDisabledChallengeMessage(false)
        }
        const cookies = new Cookies();
        cookies.set('challengeScrollposition', id);
    }
    const cardclickHandler2 = (e, id) => {
        const cookies = new Cookies();
        cookies.set('challengeScrollposition', id);

    }
    const closeDialogHandler = () => {
        setDisabledChallengeMessage(false)
    }


    return (
        <>
            <Dialog
                hideBackdrop={false}
                open={disabledChallengeMessage}
                onClose={closeDialogHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Alert
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Looks like you're jumping ahead. Complete the earlier parts to access this!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <button className="dragLabelcancel leftmargin" onClick={() => closeDialogHandler()}>
                        Ok
                    </button>
                </DialogActions>
            </Dialog>


            <div className="challengeTopic border-bottom-last">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className='challengeTitlecss'>{props.title}</h4>
                    {/* {
                        props.index === 1 && props.isinteractiveproblem === true ? (<button onClick={handlecreatehomework}
                            style={{ backgroundColor: "transparent", color: "blue", fontWeight: "400" }}> Homework</button>) : null
                    } */}
                </div>
                <div className='questions'>
                    <ImageList cellHeight={"auto"} className={classes.gridList} >
                        {
                            props.data.map((item, parentindex) => {
                                // debugger;
                                let classname = ""
                                var wrapclassname = "challengeTxt"
                                let sm = "6"
                                let fullCard = ""
                                var ps = item.value[0].problem_statement
                                const key = item.key
                                // let pstitle = "";
                                if (ps !== "" && ps !== '' && ps !== null && ps !== undefined && ps !== "undefined" && ps !== "null") {
                                    classname = ""
                                    fullCard = "fullcard"
                                    sm = "12"
                                    let str = ps.replace(/(<([^>]+)>)/ig, '');
                                    //    pstitle = parse(str)
                                    wrapclassname = "challengeTxt"
                                }
                                return (
                                    <>
                                        {
                                            ps !== "" && ps !== '' && ps !== null && ps !== undefined && ps !== "undefined" && ps !== "null" ?
                                                (

                                                    <ImageList className={classes.gridList} >
                                                        <ImageListItem key="Subheader" cols={2} style={{ height: 'auto' }} className={getCurrentUserId() === 1547 && parentindex === 0 ? "mt-2" : "border-top mt-2"}>
                                                            <ListSubheader component="div" className="headertext">
                                                                {item.value[0].problem_statement ?
                                                                    <span className={wrapclassname}> {parse(item.value[0].problem_statement)} </span>
                                                                    : null
                                                                }
                                                                {

                                                                    props.userrole !== User_Role.Student && props.userrole !== User_Role.Tutor ?
                                                                        (
                                                                            <div>

                                                                                <OverlayTrigger
                                                                                    key="top"
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip id={`tooltip-top`}>
                                                                                            Add Challenge
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span style={{ paddingLeft: "10px", color: "#007bff", cursor: "pointer" }}
                                                                                    //onClick={(e) => addchallenge(e, ps, key)}
                                                                                    >
                                                                                        <Link className='linkbutton'

                                                                                            to={{
                                                                                                pathname: `addchallenges`,
                                                                                                state: {
                                                                                                    "id": props.section_id,
                                                                                                    "sub_section_name": props.sub_section_name,
                                                                                                    "section_name": props.section_name,
                                                                                                    "chapter_name": props.chapter_name,
                                                                                                    "problemstatement": ps,
                                                                                                    "ismultipartdata": true,
                                                                                                    "groupid": parseInt(key.substring(1), 10)
                                                                                                }

                                                                                            }}
                                                                                        > <i class="fa fa-plus" aria-hidden="true"></i> Challenge</Link></span>
                                                                                </OverlayTrigger>
                                                                            </div>

                                                                        ) : null
                                                                }

                                                            </ListSubheader>
                                                        </ImageListItem>
                                                        {
                                                            item.value.map((challenge, index) => {
                                                                // debugger;

                                                                let editsolutionlink = `/solutiongraph/${challenge.id}-${challenge.type}`
                                                                if (challenge.type === "Text") {
                                                                    editsolutionlink = `/solutiongraphtext/${challenge.id}`
                                                                }
                                                                let str = challenge.question.replace(/(<([^>]+)>)/ig, '');
                                                                //let data1 = parse(str)
                                                                let data1 = challenge.question
                                                                let cardclassname = classes.card
                                                                if (props.userrole === User_Role.Student) {

                                                                    cardclassname = classes.cardstudent
                                                                }

                                                                showChallenge = challenge.has_QA_Test_Flag ? (getQATestFlag() ? true : false) : true
                                                                let cId = challenge.id.indexOf('c') !== -1 ? challenge.id : 'c' + challenge.id
                                                                let patdata = `/answerboard/${cId}`
                                                                let checkcardisdisabled = false
                                                                if (challenge.isfromhomework) {
                                                                    const today = dayjs(new Date());
                                                                    const startdate = dayjs(new Date(challenge.startdate));
                                                                    const enddate = dayjs(new Date(challenge.enddate));

                                                                    if (!today.isBetween(startdate, enddate)) {
                                                                        // if (props.userrole === User_Role.Student) {
                                                                        patdata = ""
                                                                        cardclassname = classes.carddisabled
                                                                        checkcardisdisabled = true
                                                                        // }
                                                                    }
                                                                }
                                                                else {
                                                                    if (challenge.challenge_disabled === true) {
                                                                        if (props.userrole === User_Role.Student) {
                                                                            patdata = ""
                                                                            //cardclassname = classes.cardstudent 
                                                                        }

                                                                    }
                                                                    checkcardisdisabled = false
                                                                }

                                                                let challengedisplay = true
                                                                if (props.isinteractiveproblem) {

                                                                    if (afteraddhwfinalids.indexOf(challenge.id) != -1) {
                                                                        challengedisplay = false
                                                                    }
                                                                    else {
                                                                        challengedisplay = true
                                                                    }
                                                                }

                                                                return (
                                                                    <>
                                                                        {
                                                                            showChallenge ? (
                                                                                <>
                                                                                    {
                                                                                        <>

                                                                                            <Card className={cardclassname} style={{ position: 'relative' }}

                                                                                            >
                                                                                                {
                                                                                                    props.isinteractiveproblem && (getCurrentUserRole() === User_Role.ContentAuthor || getCurrentUserRole() === User_Role.Professor) ? (
                                                                                                        challenge.status === Challenge_status.released ?
                                                                                                            <div title='homework'
                                                                                                                style={{ textAlign: "right" }}
                                                                                                            >
                                                                                                                <input
                                                                                                                    style={{
                                                                                                                        // position: 'absolute',
                                                                                                                        // top: "3px",
                                                                                                                        // right: "5px"
                                                                                                                        marginLeft: "5px",
                                                                                                                        marginRight: "5px"

                                                                                                                    }}
                                                                                                                    id={challenge.id}
                                                                                                                    type="checkbox"
                                                                                                                    className='addtohomeworkcheckbox'
                                                                                                                    // checked={false}  
                                                                                                                    onChange={(e) => handleChangecheckbox(e, challenge.id)}

                                                                                                                />
                                                                                                                <label style={{ paddingRight: '10px' }} class="addhomeworkcss">Add to Assignment</label>


                                                                                                            </div>
                                                                                                            : null) : null
                                                                                                }
                                                                                                <Link
                                                                                                    className="cardlink"
                                                                                                    id={"challenge" + challenge.id}
                                                                                                    onClick={(e) => cardclickHandler(e, patdata, "challenge" + challenge.id)}
                                                                                                    // style={{ marginTop: "10px" }}
                                                                                                    to={{
                                                                                                        pathname: patdata,
                                                                                                        state: { prevPath: ['/curriculum-problems'], prevNode: ['Interactive Problems'], problemSource: [`${Problem_source.interactiveproblems}`] }
                                                                                                    }}
                                                                                                >

                                                                                                    {challenge.has_try_this_pin && getTryThisPinsEnabledFlag() ?
                                                                                                        (
                                                                                                            <>
                                                                                                                <TryThisPin
                                                                                                                    showPin
                                                                                                                    style={{
                                                                                                                        position: 'absolute',
                                                                                                                        //left: '0px', top: '0px'
                                                                                                                    }}
                                                                                                                />
                                                                                                                <CardMedia
                                                                                                                    title="Click here to solve this problem"
                                                                                                                    component="img"
                                                                                                                    image={imgURL + challenge.thumbnail}
                                                                                                                    className={classes.contentimage}
                                                                                                                />
                                                                                                            </>
                                                                                                        )
                                                                                                        :
                                                                                                        (
                                                                                                            <>
                                                                                                                <CardMedia
                                                                                                                    title="Click here to solve this problem"
                                                                                                                    component="img"
                                                                                                                    image={imgURL + challenge.thumbnail}
                                                                                                                    className={classes.contentimage}
                                                                                                                />
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        challenge.has_QA_Test_Flag ?
                                                                                                            // <div className='betaImage' title='Beta testing'></div> : null
                                                                                                            <div title='Beta testing'>
                                                                                                                <img src={betaimage} style={{
                                                                                                                    position: 'absolute',
                                                                                                                    width: "80px",
                                                                                                                    //height: "80px",
                                                                                                                    top: "-4px",
                                                                                                                    left: "-18px"

                                                                                                                }}></img>
                                                                                                            </div> : null
                                                                                                    }

                                                                                                    <CardContent className={classes.contentclass}>
                                                                                                        <Typography className='ch-cardstitle'>
                                                                                                            <OverlayTrigger
                                                                                                                key="title1"
                                                                                                                placement="top"
                                                                                                                overlay={
                                                                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                                                                        {challenge.display_name}
                                                                                                                    </Tooltip>
                                                                                                                }
                                                                                                            >
                                                                                                                <div style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', fontWeight: "600" }}>
                                                                                                                    <span className="ch-title"> {challenge.display_name}</span>
                                                                                                                </div>
                                                                                                            </OverlayTrigger>
                                                                                                            <div >
                                                                                                                {
                                                                                                                    (props.userrole !== User_Role.Student && props.userrole !== User_Role.Tutor) ?
                                                                                                                        (<Dropdown >
                                                                                                                            <Dropdown.Toggle as={CustomToggle} />
                                                                                                                            <Dropdown.Menu size="sm" title="" >

                                                                                                                                <>
                                                                                                                                    <Dropdown.Item >
                                                                                                                                        <Link
                                                                                                                                            to={`/addchallenges/${challenge.id}`}
                                                                                                                                            style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                            <i class="fa fa-edit" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i> Edit Challenge</Link>
                                                                                                                                    </Dropdown.Item>
                                                                                                                                    <Dropdown.Item >
                                                                                                                                        {
                                                                                                                                            challenge.status === Challenge_status.released ? (
                                                                                                                                                <OverlayTrigger
                                                                                                                                                    key="bottom"
                                                                                                                                                    placement="bottom"
                                                                                                                                                    overlay={
                                                                                                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                                                                                                            Kindly change the challenge status to "Under Development" using the above "Edit Challenge" link to request edits to the solution!
                                                                                                                                                        </Tooltip>
                                                                                                                                                    }
                                                                                                                                                >
                                                                                                                                                    <Link to={editsolutionlink}
                                                                                                                                                        style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                                        <i class="fa fa-folder-open-o" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i> View Solution
                                                                                                                                                    </Link>
                                                                                                                                                </OverlayTrigger>
                                                                                                                                            ) : (
                                                                                                                                                <Link to={editsolutionlink}
                                                                                                                                                    style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                                    <i class="fa fa-folder-open-o" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i> Edit Solution
                                                                                                                                                </Link>)

                                                                                                                                        }
                                                                                                                                    </Dropdown.Item>
                                                                                                                                    {
                                                                                                                                        challenge.type === "FRQ" ? (<Dropdown.Item >
                                                                                                                                            <Link
                                                                                                                                                to={{
                                                                                                                                                    pathname: `/addeditsubstitution/${challenge.id}`,
                                                                                                                                                    state: {
                                                                                                                                                        challengename: `${challenge.title}`
                                                                                                                                                    }
                                                                                                                                                }}
                                                                                                                                                style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                                <i class="fa fa-edit" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i> Substitution Symbols</Link>
                                                                                                                                        </Dropdown.Item>) : null
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                            </Dropdown.Menu>
                                                                                                                        </Dropdown>) : null
                                                                                                                }
                                                                                                            </div>
                                                                                                        </Typography>
                                                                                                        <Row className='status-title-source mt-1 mb-1 font-italic'>
                                                                                                            <Col sm="12">
                                                                                                                <div className='statusContainer'>
                                                                                                                    <div>({challenge.section_name})</div>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <Typography>
                                                                                                            <span class="questionfontsmulti">{parse(data1)}</span>
                                                                                                        </Typography>
                                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                                            <Typography className='ch-cardstext'>
                                                                                                                <div className='challenge-status'>

                                                                                                                    {
                                                                                                                        props.userrole !== User_Role.Student && props.userrole !== User_Role.Tutor ? (<Row className='status-title'>
                                                                                                                            <Col sm="12">
                                                                                                                                <span>{challenge.status}</span>
                                                                                                                            </Col>
                                                                                                                        </Row>) : null
                                                                                                                    }
                                                                                                                    <Row className='status-title-source'>
                                                                                                                        <Col sm="12">
                                                                                                                            <div className='statusContainer'>

                                                                                                                                <div>Source: {challenge.bookname}</div>
                                                                                                                            </div>
                                                                                                                        </Col>
                                                                                                                    </Row>

                                                                                                                </div></Typography>

                                                                                                            <Typography className='ch-cardstext'>
                                                                                                                <div className='challenge-status'>
                                                                                                                    <Row className='status-title'>
                                                                                                                        <Col sm="12">
                                                                                                                            <div className='statusContainer'>
                                                                                                                                <OverlayTrigger
                                                                                                                                    key="bottom"
                                                                                                                                    placement="bottom"
                                                                                                                                    overlay={
                                                                                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                                                                                            Free Response Questions
                                                                                                                                        </Tooltip>
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <span >{challenge.type}
                                                                                                                                    </span>

                                                                                                                                </OverlayTrigger>

                                                                                                                                <OverlayTrigger
                                                                                                                                    key="bottom"
                                                                                                                                    placement="bottom"
                                                                                                                                    overlay={
                                                                                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                                                                                            {challenge.complexity}
                                                                                                                                        </Tooltip>
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <div title={challenge.complexity}
                                                                                                                                        style={{ marginLeft: '4px', width: '15px', height: '15px', background: getColor(challenge.complexity) }}>

                                                                                                                                    </div>
                                                                                                                                </OverlayTrigger>

                                                                                                                            </div>
                                                                                                                        </Col>
                                                                                                                    </Row>

                                                                                                                </div></Typography>
                                                                                                        </div>
                                                                                                    </CardContent>
                                                                                                </Link>
                                                                                            </Card>
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            ) : null
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </ImageList >

                                                ) :
                                                (
                                                    <>

                                                        {
                                                            item.value.map((challenge, index) => {

                                                                let editsolutionlink = `/solutiongraph/${challenge.id}-${challenge.type}`
                                                                if (challenge.type === "Text") {
                                                                    editsolutionlink = `/solutiongraphtext/${challenge.id}`
                                                                }
                                                                let str = challenge.question.replace(/(<([^>]+)>)/ig, '');
                                                                // let data1 = parse(str)
                                                                let data1 = challenge.question

                                                                showChallenge = challenge.has_QA_Test_Flag ? (getQATestFlag() ? true : false) : true
                                                                let cardclass = classes.card
                                                                if (props.userrole == User_Role.Student) {
                                                                    cardclass = classes.cardstudent
                                                                }
                                                                let cId = challenge.id.indexOf('c') !== -1 ? challenge.id : 'c' + challenge.id
                                                                let patdata = `/answerboard/${cId}`
                                                                let checkcardisdisabled = false
                                                                if (challenge.isfromhomework) {
                                                                    const today = dayjs(new Date());
                                                                    const startdate = dayjs(new Date(challenge.startdate));
                                                                    const enddate = dayjs(new Date(challenge.enddate));
                                                                    if (!today.isBetween(startdate, enddate)) {
                                                                        // if (props.userrole === User_Role.Student) {
                                                                        patdata = ""
                                                                        cardclass = classes.carddisabled
                                                                        checkcardisdisabled = true
                                                                        // }
                                                                    }

                                                                }
                                                                else {
                                                                    checkcardisdisabled = false
                                                                }


                                                                let challengedisplay = true
                                                                if (props.isinteractiveproblem) {

                                                                    if (afteraddhwfinalids.indexOf(challenge.id) != -1) {
                                                                        challengedisplay = false
                                                                    }
                                                                    else {
                                                                        challengedisplay = true
                                                                    }
                                                                }

                                                                return (
                                                                    <>
                                                                        {
                                                                            showChallenge ? (
                                                                                <>
                                                                                    {
                                                                                        <>
                                                                                            <Card className={cardclass} style={{ position: 'relative' }}

                                                                                            >
                                                                                                {
                                                                                                    props.isinteractiveproblem && (getCurrentUserRole() === User_Role.ContentAuthor || getCurrentUserRole() === User_Role.Professor) ? (
                                                                                                        challenge.status === Challenge_status.released ?
                                                                                                            <div title='homework'
                                                                                                                style={{ textAlign: "right" }}
                                                                                                            >

                                                                                                                <input
                                                                                                                    style={{
                                                                                                                        // position: 'absolute',
                                                                                                                        // top: "3px",
                                                                                                                        // right: "5px"
                                                                                                                        marginLeft: "5px",
                                                                                                                        marginRight: "5px"

                                                                                                                    }}
                                                                                                                    id={challenge.id}
                                                                                                                    type="checkbox"
                                                                                                                    className='addtohomeworkcheckbox2'
                                                                                                                    // checked={false}
                                                                                                                    onChange={(e) => handleChangecheckbox(e, challenge.id)}

                                                                                                                />
                                                                                                                <label style={{ paddingRight: '10px' }} class="addhomeworkcss">Add to Assignment</label>
                                                                                                            </div>
                                                                                                            : null) : null
                                                                                                }
                                                                                                <Link
                                                                                                    // style={{ marginTop: "10px" }}
                                                                                                    id={"challenge" + challenge.id}
                                                                                                    onClick={(e) => cardclickHandler2(e, "challenge" + challenge.id)}
                                                                                                    className="cardlink"
                                                                                                    to={{
                                                                                                        pathname: patdata,
                                                                                                        state: { prevPath: ['/curriculum-problems'], prevNode: ['Interactive Problems'], problemSource: [`${Problem_source.interactiveproblems}`] }
                                                                                                    }}
                                                                                                >
                                                                                                    {challenge.has_try_this_pin && getTryThisPinsEnabledFlag() ?
                                                                                                        (
                                                                                                            <>
                                                                                                                <TryThisPin
                                                                                                                    showPin
                                                                                                                    style={{
                                                                                                                        position: 'absolute',
                                                                                                                        bottom: "0"
                                                                                                                        //left: '0px', top: '0px'
                                                                                                                    }}
                                                                                                                />

                                                                                                                <CardMedia
                                                                                                                    title="Click here to solve this problem"
                                                                                                                    component="img"
                                                                                                                    image={imgURL + challenge.thumbnail}
                                                                                                                    className={classes.contentimage}
                                                                                                                />
                                                                                                            </>
                                                                                                        )
                                                                                                        :
                                                                                                        (
                                                                                                            <>
                                                                                                                <CardMedia
                                                                                                                    title="Click here to solve this problem"
                                                                                                                    component="img"
                                                                                                                    image={imgURL + challenge.thumbnail}
                                                                                                                    className={classes.contentimage}
                                                                                                                />
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        challenge.has_QA_Test_Flag ?
                                                                                                            <div title='Beta testing'>

                                                                                                                <img src={betaimage} style={{
                                                                                                                    position: 'absolute',
                                                                                                                    width: "80px",
                                                                                                                    //height: "80px",
                                                                                                                    top: "-4px",
                                                                                                                    left: "-18px"

                                                                                                                }}></img>
                                                                                                            </div> : null
                                                                                                    }



                                                                                                    <CardContent className={classes.contentclass}>
                                                                                                        <Typography className='ch-cardstitle'>

                                                                                                            <OverlayTrigger
                                                                                                                key="title2"
                                                                                                                placement="top"
                                                                                                                overlay={
                                                                                                                    <Tooltip id={`tooltip-bottom`}>
                                                                                                                        {challenge.display_name}
                                                                                                                    </Tooltip>
                                                                                                                }
                                                                                                            >
                                                                                                                <div style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', fontWeight: "600" }}>
                                                                                                                    <span className="ch-title"> {challenge.display_name}</span>
                                                                                                                </div>
                                                                                                            </OverlayTrigger>

                                                                                                            <div >
                                                                                                                {
                                                                                                                    (props.userrole !== User_Role.Student && props.userrole !== User_Role.Tutor) ?
                                                                                                                        (<Dropdown >
                                                                                                                            <Dropdown.Toggle as={CustomToggle} />

                                                                                                                            <Dropdown.Menu size="sm" title="" >

                                                                                                                                <>
                                                                                                                                    <Dropdown.Item >
                                                                                                                                        <Link
                                                                                                                                            to={`/addchallenges/${challenge.id}`}
                                                                                                                                            style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                            <i class="fa fa-edit" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i> Edit Challenge</Link>
                                                                                                                                    </Dropdown.Item>
                                                                                                                                    <Dropdown.Item >
                                                                                                                                        {
                                                                                                                                            challenge.status === Challenge_status.released ? (
                                                                                                                                                <OverlayTrigger
                                                                                                                                                    key="bottom"
                                                                                                                                                    placement="bottom"
                                                                                                                                                    overlay={
                                                                                                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                                                                                                            Kindly change the challenge status to "Under Development" using the above "Edit Challenge" link to request edits to the solution!
                                                                                                                                                        </Tooltip>
                                                                                                                                                    }
                                                                                                                                                >
                                                                                                                                                    <Link to={editsolutionlink}
                                                                                                                                                        style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                                        <i class="fa fa-folder-open-o" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i> View Solution
                                                                                                                                                    </Link>
                                                                                                                                                </OverlayTrigger>
                                                                                                                                            ) : (
                                                                                                                                                <Link to={editsolutionlink}
                                                                                                                                                    style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                                    <i class="fa fa-folder-open-o" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i> Edit Solution
                                                                                                                                                </Link>)

                                                                                                                                        }
                                                                                                                                    </Dropdown.Item>
                                                                                                                                    {
                                                                                                                                        challenge.type === "FRQ" ? (<Dropdown.Item >
                                                                                                                                            <Link
                                                                                                                                                to={{
                                                                                                                                                    pathname: `/addeditsubstitution/${challenge.id}`,
                                                                                                                                                    state: {
                                                                                                                                                        challengename: `${challenge.title}`
                                                                                                                                                    }
                                                                                                                                                }}
                                                                                                                                                style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                                <i class="fa fa-edit" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i> Substitution Symbols</Link>
                                                                                                                                        </Dropdown.Item>) : null
                                                                                                                                    }

                                                                                                                                </>
                                                                                                                            </Dropdown.Menu>
                                                                                                                        </Dropdown>) : null
                                                                                                                }
                                                                                                            </div>
                                                                                                        </Typography>
                                                                                                        <Row className='status-title-source mt-1 mb-1 font-italic'>
                                                                                                            <Col sm="12">
                                                                                                                <div className='statusContainer'>
                                                                                                                    <div>({challenge.section_name})</div>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <Typography>
                                                                                                            <span class="questionfontsmulti">{parse(data1)}</span>
                                                                                                        </Typography>




                                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>

                                                                                                            <Typography className='ch-cardstext'>

                                                                                                                <div className='challenge-status'>

                                                                                                                    {
                                                                                                                        props.userrole !== User_Role.Student && props.userrole !== User_Role.Tutor ? (<Row className='status-title'>
                                                                                                                            <Col sm="12">
                                                                                                                                <span>{challenge.status}</span>
                                                                                                                            </Col>
                                                                                                                        </Row>) : null
                                                                                                                    }
                                                                                                                    <Row className='status-title-source'>
                                                                                                                        <Col sm="12">
                                                                                                                            <div className='statusContainer'>
                                                                                                                                <div>Source: {challenge.bookname}</div>

                                                                                                                            </div>
                                                                                                                        </Col>
                                                                                                                    </Row>


                                                                                                                </div></Typography>

                                                                                                            <Typography className='ch-cardstext'>
                                                                                                                <div className='challenge-status'>
                                                                                                                    <Row className='status-title'>
                                                                                                                        <Col sm="12">
                                                                                                                            <div className='statusContainer'>
                                                                                                                                <OverlayTrigger
                                                                                                                                    key="bottom"
                                                                                                                                    placement="bottom"
                                                                                                                                    overlay={
                                                                                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                                                                                            Full Response Questions
                                                                                                                                        </Tooltip>
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <span >{challenge.type}
                                                                                                                                    </span>

                                                                                                                                </OverlayTrigger>



                                                                                                                                <OverlayTrigger
                                                                                                                                    key="bottom"
                                                                                                                                    placement="bottom"
                                                                                                                                    overlay={
                                                                                                                                        <Tooltip id={`tooltip-bottom`}>
                                                                                                                                            {challenge.complexity}
                                                                                                                                        </Tooltip>
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <div title={challenge.complexity}
                                                                                                                                        style={{ marginLeft: '4px', width: '15px', height: '15px', background: getColor(challenge.complexity) }}>

                                                                                                                                    </div>
                                                                                                                                </OverlayTrigger>


                                                                                                                            </div>

                                                                                                                        </Col>
                                                                                                                    </Row>


                                                                                                                </div></Typography></div>
                                                                                                    </CardContent>


                                                                                                </Link>
                                                                                            </Card>
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            ) : null
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                        }
                                    </>
                                )
                            })
                        }
                    </ImageList>
                </div>
            </div >
        </>
    );
}
export default withRouter(React.memo(TopicChallenges));