import React from 'react';
import "../../components/Challenges/TopicChallenges/TopicChallenges.scss"
import { withRouter } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import { imgURL } from '../../common/API';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import parse from 'html-react-parser';


import {
    Card,
    CardContent,
    CardMedia,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(3),
            width: "25ch"
        }
    },
    gridList: {
        height: "auto",
        position: "relative",
        overflowY: "unset",
        marginBottom: "10px !important",
        width: "100%",
        // cursor: "auto"
    },
    card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        margin: "10px",
        borderRadius: "10px",
        //position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#fff",
        // cursor: "auto",
        // width: "97%",
        // padding:"10px"
    },
    cardstudent: {
        // minWidth: "300px",
        // height: "370px",
        margin: "10px",
        borderRadius: "10px",
        //position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#fff",
        // cursor: "auto",
        width: "97%"
    },
    carddisabled: {
        // minWidth: "300px",
        // height: "340px",
        margin: "10px",
        borderRadius: "10px",
        //position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#f1f1f1"
    },


    contentimage: {
        padding: "5px",
        borderRadius: "12px",
        height: "150px !important",
        objectFit: "contain",
        pointerEvents: "fill",
        maxWidth: '350px !important',
        width: '250px !important'
    },
    font16: {
        fontSize: "16px !important",
    },
    datepickercls: {
        marginTop: "0px !important"
    },
    contentclass: {
        padding: "10px !important"
    },
    complexityTooltip: {
        width: "180px !important",
        // width: "92px",
        height: "36px",
        borderRadius: "18px",
        boxShadow: "0 20px 80px 0",
        backgroundColor: "red"
    },
    selectedCard: {
        background: "#b9d7f1"
    },

}));

const AssignmentChallengeListItem = (props) => {
    const classes = useStyles();
    let cardClassName = classes.card
    if (props.isSelectedChallenge) {
        cardClassName = cardClassName + " " + classes.selectedCard
    }
    const getColor = (complexity) => {
        if (complexity == "Medium")
            return 'yellow'
        else if (complexity == "Easy")
            return "green"
        else
            return 'orange';
    }
    return (
        <>
            <Card className={cardClassName} style={{ display: 'block', position: 'relative' }}>


                <div
                    className='d-flex align-items-center justify-content-end'
                    style={{ position: 'relative', textAlign: "right", marginBottom: "15px" }}

                >
                    {
                        !props.isLastchallenge ?
                            (<>
                                <div
                                    onClick={props.deletedId}
                                    title='Delete this challenge from assignment'
                                    style={{
                                        fontSize: "14px", boxShadow: "none", textDecoration: 'none', marginLeft: "5px",
                                        marginRight: "5px", position: "relative", top: "0", right: "0", cursor: "pointer"

                                    }}>
                                    <i class="fa fa-trash" style={{ fontSize: "14px", boxShadow: "none", textDecoration: 'none', }}></i><span style={{ fontSize: '11px', marginLeft: "3px" }}>Remove</span>
                                </div>
                            </>) : null
                    }
                    {
                        props.graded ? (<div
                            onClick={props.editpointhandler}
                            title='Update Grades'
                            style={{
                                fontSize: "14px", boxShadow: "none", textDecoration: 'none', marginLeft: "5px",
                                marginRight: "5px", position: "relative", top: "0", right: "0", cursor: "pointer"
                            }}>
                            <i class="fa fa-edit" style={{ fontSize: "14px", boxShadow: "none", textDecoration: 'none', }}></i><span style={{ fontSize: '11px', marginLeft: "3px" }}>Update Grades</span>
                        </div>) : null
                    }

                </div>
                <CardMedia
                    title="Click here to solve this problem"
                    component="img"
                    image={imgURL + props.data.thumbnail}
                    className={classes.contentimage}
                />
                <CardContent className={classes.contentclass}>
                    <OverlayTrigger
                        key="title1"
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-bottom`}>
                                {props.data.display_name}
                            </Tooltip>
                        }
                    >
                        <Typography className='questionfontsmultiassignmenttitle'>
                            <span className="ch-title">{props.data.display_name}</span>
                        </Typography>
                    </OverlayTrigger>
                    <Row className='status-title-source mt-1 mb-1 font-italic'>
                        <Col sm="12">
                            <div className='statusContainer'>
                                <div>({props.data.section_name})</div>
                            </div>
                        </Col>
                    </Row>
                    <Typography className='questionfontsmultiassignment'>
                        <span>{parse(props.data.question)}</span>
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "space-between" }} className='maxwidthAssignment'>
                        <Typography className='ch-cardstext maxwidthAssignment'>
                            <div className='challenge-status'>
                                <Row className='status-title-source'>
                                    <Col sm="12">
                                        <div className='statusContainer'>
                                            <div>Source: {props.data.bookname}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div></Typography>
                        <Typography className='ch-cardstext maxwidthAssignment'>
                            <div className='challenge-status'>
                                <Row className='status-title'>
                                    <Col sm="12">
                                        <div className='statusContainer'>
                                            <OverlayTrigger
                                                key="bottom"
                                                placement="bottom"
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                        {props.data.type}
                                                    </Tooltip>
                                                }
                                            >
                                                <span >{props.data.type}
                                                </span>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key="bottom"
                                                placement="bottom"
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                        {props.data.complexity}
                                                    </Tooltip>
                                                }
                                            >
                                                <div title={props.data.complexity}
                                                    style={{ marginLeft: '4px', width: '15px', height: '15px', background: getColor(props.data.complexity) }}>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Typography>
                    </div>
                </CardContent>
            </Card>
        </>
    );
}
export default withRouter(React.memo(AssignmentChallengeListItem));