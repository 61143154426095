import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    update_nonfrqReview, studentnonfrqhomework_detail, imgURL
} from '../../common/API'
import Aux from '../../hoc/Wrapper';
import Button from '../Common/Button/Button';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import { getCurrentUserId, Problem_source, getCurrentUserRole, User_Role } from '../../common/Functions';
import HintIcon from '../../assets/images/icon-hint-show.svg';
import checkWorkIcon from '../../assets/images/icon-check.svg';
import ShowNextstepIcon from '../../assets/images/icon-show-next-step.svg';
import ShowFullSolutionIcon from '../../assets/images/icon-show-full-solution.svg';
import CheckMyworkResultCNonFRQ from '../Answerboard/CheckMyworkResultCNonFRQ';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


const HomeworkReviewDetailNonFRQ = (props) => {

    const [loading, setloading] = useState(false)
    const [modalData1, setmodalData1] = useState([])
    const [hintDivVisible, setHintDivVisible] = useState(false)
    const [checkMyWorkDivVisible, setCheckMyWorkDivVisible] = useState(false)
    const [showNextStepDivVisible, setShowNextStepDivVisible] = useState(false)
    const [showSolutionDivVisible, setShowSolutionDivVisible] = useState(false)
    const [reSubmitMessageShow, setReSubmitMessageShow] = useState(false)
    const [displayMessag, setdisplayMessag] = React.useState("");
    const [asgreviewmasterdata, setasgreviewmasterdata] = useState([])
    useEffect(() => {
        debugger;
        setloading(true)
        setdisplayMessag("Fetching Data...")
        let reqData = {
            challenge_id: props.challenge_id,
            studentId: props.studentId,
            assignment_id: props.assignment_id,
            problem_source: Problem_source.assignment
        }
        studentnonfrqhomework_detail({ params: reqData }).then(res => {
            if (res.data.data[0] !== undefined) {
                setasgreviewmasterdata(res.data.data[0])
                if (props.totalcheckmyworkTrial > 0) {
                    let getpoints = res.data.data[0].totalgrade
                    let pointdifference = props.totalSectionPoints - getpoints
                    if (pointdifference > 0 && props.totalSectionPoints > 0) {
                        let getpercentage = (100 * pointdifference) / props.totalSectionPoints
                        if (getpercentage > 0) {
                            let attemptcount = (5 * getpercentage) / 100
                            props.setdeductedpointshandle(getpercentage)
                            props.setattemptedIncorrecthandle(attemptcount)
                        }
                    }                 
                }

                if (res.data.data[0].submit_id !== undefined && res.data.data[0].submit_id !== null) {
                    if (parseInt(res.data.data[0].submit_id) > 1)
                        setReSubmitMessageShow(true)
                    else {
                        setReSubmitMessageShow(false)
                    }
                }
                else {
                    setReSubmitMessageShow(false)
                }
                if (res.data.data[0].children !== undefined && res.data.data[0].children !== null && res.data.data[0].children.length > 0) {
                    setmodalData1(res.data.data[0].children)
                    setloading(false)
                }
                else {
                    resetdta()

                }
            }
            else {
                resetdta()
                setdisplayMessag("No Data Found!")
            }


        }).catch(err => {
            setloading(false)
            alert(err.message)
            console.error(err.message);
        })
    }, [])

    const resetdta = () => {
        setmodalData1(null)
        setloading(false)
        props.showreviewdnonFRQModalclose()
    }



    const handleFeedbackChangeEvent = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData1 !== undefined && modalData1.length > 0) {
            changeData = [...modalData1]
            if (changeData[index] !== undefined) {
                let tempArr = changeData[index]
                tempArr.ta_feedback = e.target.value
                changeData[index] = tempArr
                setmodalData1(changeData)
            }
        }
    }
    const handleHintPointsChangeEvent = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData1 !== undefined && modalData1.length > 0) {
            changeData = [...modalData1]
            if (changeData[index] !== undefined) {
                let tempArr = changeData[index]
                tempArr.ta_hint_points = e.target.value
                changeData[index] = tempArr
                setmodalData1(changeData)
            }
        }
    }

    const handlePointsChangeEvent = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData1 !== undefined && modalData1.length > 0) {
            changeData = [...modalData1]
            if (changeData[index] !== undefined) {
                let tempArr = changeData[index]
                tempArr.points = e.target.value
                changeData[index] = tempArr
                setmodalData1(changeData)
                debugger;
                
            }
        }
    }

    const handleUpdate = () => {
        let tafeedbackData = []
        let ta_totalgrades = 0
        let ta_hint_points = 0
        modalData1.map((item) => {
            // debugger;
            //ta_hint_points = parseFloat(ta_hint_points, 2) - (parseFloat(item.ta_hint_points, 2))
            if (Boolean(item.is_answer_correct) === true) {
                tafeedbackData.push({
                    "is_answer_correct": item.is_answer_correct,
                    "id": item.id,
                    "points": item.points,
                    "ta_feedback": item.ta_feedback,
                    "ta_hint_points": item.ta_hint_points
                },
                )

                ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.points, 2)
                // ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.hint_points, 2)

                if (parseFloat(item.ta_hint_points, 2) < 0) {
                    ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.ta_hint_points, 2)
                }
                else {
                    ta_totalgrades = parseFloat(ta_totalgrades, 2) - parseFloat(item.ta_hint_points, 2)
                }

            }
            else {
                tafeedbackData.push({
                    "is_answer_correct": item.is_answer_correct,
                    "id": item.id,
                    "points": item.points,
                    "ta_feedback": item.ta_feedback,
                    "ta_hint_points": item.ta_hint_points
                },
                )
                if (parseFloat(item.points, 2) < 0) {
                    ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.points, 2)
                }
                else {
                    ta_totalgrades = parseFloat(ta_totalgrades, 2) - parseFloat(item.points, 2)
                }
            }
            return tafeedbackData

        })
        debugger;
        // alert(ta_hint_points)
        console.log("ta feedback json - " + JSON.stringify(tafeedbackData))
        let form_data = new FormData();
        form_data.append("ta_user_data", JSON.stringify(tafeedbackData))
        form_data.append("problem_source", Problem_source.assignment)
        form_data.append("challengeId", props.challenge_id)
        form_data.append("ta_user_id", getCurrentUserId())
        form_data.append("user_id", props.studentId)
        form_data.append("assignment_id", props.assignment_id)
        form_data.append("boardId", props.boardId)
        form_data.append("ta_totalgrades", ta_totalgrades)
        form_data.append("ta_hint_points", ta_hint_points)

        update_nonfrqReview(form_data).then(res => {
            if (res.status === 200) {
                toast.success("Updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                props.showreviewdnonFRQModalclose()
                props.nonFRQpointschangedHandler(props.questionType,ta_totalgrades, props.showfeedbackSelectedQuestionIndex)
            }
        }).catch(err => {
            toast.error("Error!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            console.error(err.message)
        })
    }
    const hinticonhandler = () => {
        setHintDivVisible(!hintDivVisible)
    }
    const checkmyworkiconhandler = () => {
        setCheckMyWorkDivVisible(!checkMyWorkDivVisible)
    }

    const showNextStephandler = () => {
        setShowNextStepDivVisible(!showNextStepDivVisible)
    }

    const showSholutionhandler = () => {
        setShowSolutionDivVisible(!showSolutionDivVisible)
    }

    return (
        <>
            <div className='w-100 d-flex bg-white pr-2' >
                <div className={'div100'}>
                    {
                        asgreviewmasterdata !== null && asgreviewmasterdata !== undefined ?
                            (
                                <>
                                    {asgreviewmasterdata.isgraded ?
                                        <div style={{ paddingLeft: "20px", background: "#fff", maxWidth: "700px" }} className='popupSectionTitle'>
                                            Total Points: {asgreviewmasterdata.totalgrade}/{asgreviewmasterdata.totalnonfrqpoints}
                                            {
                                                parseInt(props.isPtSubmitted) === 1 ? (<span style={{ paddingLeft: "5px", fontSize: "13px" }}>
                                                    (Important: Your grades and feedback are based on your submitted solutions only.)
                                                </span>) : null
                                            }
                                        </div>
                                        : null
                                    }
                                    {
                                        modalData1 !== undefined && modalData1 !== null && modalData1.length > 0 ?
                                            (
                                                modalData1.map((item, index) => {
                                                    let equationclassname = "mt-1  eqfont popupSectionTitlemediumlatex"
                                                    let containerclass = ""
                                                    let spanclass = ""
                                                    if (item.is_answer_correct === true) {
                                                        equationclassname = equationclassname + " " + "green"
                                                        containerclass = "correctAnswerContainerstudent"
                                                        spanclass = "answer-correct-sign"
                                                    }
                                                    else {
                                                        equationclassname = equationclassname + " " + "red"
                                                        containerclass = "incorrectAnswerContainerstudent"
                                                        spanclass = "answer-incorrect-sign"
                                                    }
                                                    let finalanswerdata = []
                                                    debugger;
                                                    let answertext = item.selected_answer_text
                                                    if (answertext.includes("###")) {
                                                        let arr = answertext.split("###")
                                                        arr.forEach((rem) => {
                                                            let contentdata1 = (
                                                                <Aux>

                                                                    <div dangerouslySetInnerHTML={{ __html: rem.toString().replace(/^,/, '').replace(/,\s*$/, "") }}>
                                                                    </div>


                                                                </Aux >
                                                            );
                                                            finalanswerdata.push(contentdata1)
                                                        })
                                                    }
                                                    else {
                                                        let contentdata1 = (
                                                            <Aux>

                                                                <div dangerouslySetInnerHTML={{ __html: answertext.toString().replace(/^,/, '').replace(/,\s*$/, "") }}>
                                                                </div>


                                                            </Aux >
                                                        );
                                                        finalanswerdata.push(contentdata1)
                                                    }
                                                    let contentdatafinal = []
                                                    let hinusedtext = ""
                                                    //debugger;
                                                    if (item.student_answer.result !== undefined && item.student_answer.result !== null) {

                                                        // if (item.is_answer_correct) {
                                                        //     getPoints = "1.00";
                                                        // }
                                                        //showsolutionused = ""
                                                        if (item.is_answer_correct === true && item.hints_used !== undefined && item.hints_used !== "" && item.hints_used !== "false" && item.hints_used !== false) {
                                                            hinusedtext = "Hint Used"
                                                        }
                                                        if (item.is_answer_correct === true && item.nextstep_used !== undefined && item.nextstep_used !== null && item.nextstep_used !== "" && item.nextstep_used !== "false" && item.nextstep_used !== false) {
                                                            if (hinusedtext !== "")
                                                                hinusedtext = hinusedtext + ", " + "Next Step Used"
                                                            else {
                                                                hinusedtext = "Next Step Used"
                                                            }
                                                        }
                                                        if (item.is_answer_correct === true && item.show_solution_used !== undefined && item.show_solution_used !== null && item.show_solution_used !== "" && item.show_solution_used !== "false" && item.show_solution_used !== false) {
                                                            if (hinusedtext !== "")
                                                                hinusedtext = hinusedtext + ", " + "Show Solution Used"
                                                            else {
                                                                hinusedtext = "Show Solution Used"
                                                            }
                                                        }
                                                        if (item.is_answer_correct === true && item.checkmyworkuseddata !== undefined && item.checkmyworkuseddata !== null && item.checkmyworkuseddata !== "" && item.checkmyworkuseddata !== "false" && item.checkmyworkuseddata !== false) {
                                                            if (hinusedtext !== "")
                                                                hinusedtext = hinusedtext + ", " + "Feedback Used"
                                                            else {
                                                                hinusedtext = "Feedbak Used"
                                                            }
                                                        }
                                                        if (item.student_answer.result.data !== undefined && item.student_answer.result.data !== null && item.student_answer.result.data.length > 0) {
                                                            // debugger;
                                                            item.student_answer.result.data.forEach((rem) => {
                                                                // debugger;
                                                                let data = ""
                                                                let data3 = ""
                                                                let data4 = ""
                                                                data = rem.remedial_text
                                                                data3 = rem.remedial_source_url
                                                                data4 = rem.remedial_source_title
                                                                if (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '') {
                                                                    data = data.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                                                    data = data.replace(".,", ".")
                                                                }
                                                                if (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') {
                                                                    data3 = data3.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                                                    data3 = data3.replace(".,", ".")
                                                                }
                                                                if (data4 && data4 !== null && data4 !== 'null' && data4 !== "null" && data4 != "undefined" && data4 !== "" && data4 !== '') {
                                                                    data4 = data4.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                                                    data4 = data4.replace(".,", ".")
                                                                }

                                                                let contentdata = (
                                                                    <Aux>
                                                                        {
                                                                            (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '' && data !== ',' && data !== ",") ?
                                                                                <div className="mb-1 mt-3" dangerouslySetInnerHTML={{ __html: data.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div>
                                                                                : null
                                                                        }

                                                                        {
                                                                            (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') ?
                                                                                <a href={data3}
                                                                                    target='_blank' rel="noopener noreferrer">
                                                                                    <div dangerouslySetInnerHTML={{ __html: data4 ? data4.toString().replace(/^,/, '').replace(/,\s*$/, "") : data3.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div></a>
                                                                                : null
                                                                        }

                                                                    </Aux>
                                                                );
                                                                contentdatafinal.push(contentdata)

                                                            })
                                                        }
                                                    }
                                                    return (
                                                        <>
                                                            <div className={containerclass}>
                                                                <span className={spanclass}></span>
                                                                <div className='answer-container'>
                                                                    <div className='d-flex align-items-center justify-content-between pb-3'>
                                                                        <div>
                                                                            <p className='yrResTxt'>Your Response</p>
                                                                            {/* <p>Correct Equation</p> */}
                                                                        </div>
                                                                        {
                                                                            item.isgraded ?
                                                                                (
                                                                                    <>
                                                                                        <div className='pointsContainer '>
                                                                                            {

                                                                                                <>
                                                                                                    <div style={{ textAlign: "end" }} className='d-flex align-items-center justify-content-end'>
                                                                                                        <span>Points:</span>
                                                                                                        {
                                                                                                            getCurrentUserRole() === User_Role.Student ? (<span style={{ marginLeft: '5px' }}>{item.points}</span>) :
                                                                                                                (
                                                                                                                    <input style={{ marginLeft: '5px' }} name="points" class="form-control bg-yellow" type='text'
                                                                                                                        onChange={(e) => handlePointsChangeEvent(e, index)}
                                                                                                                        value={item.points}></input>
                                                                                                                )
                                                                                                        }
                                                                                                    </div>

                                                                                                </>

                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    <Row className='no-gutters'>
                                                                        <Col sm="9"><div className={equationclassname}>
                                                                            {finalanswerdata}
                                                                        </div></Col>

                                                                        <Col sm="3" style={{ textAlign: "end", paddingLeft: "10px" }}>
                                                                            {item.is_answer_correct === true && item.hints_used !== undefined && item.hints_used !== "" && item.hints_used !== "false" && item.hints_used !== false ?

                                                                                (<Tooltip title="Hints Used" placement='top' arrow >
                                                                                    <IconButton onClick={hinticonhandler}>
                                                                                        <div className={`boardBtnsreview `}>
                                                                                            <img src={HintIcon} />
                                                                                        </div>
                                                                                    </IconButton>
                                                                                </Tooltip>) : null

                                                                            }
                                                                            {
                                                                                item.is_answer_correct === true && item.checkmyworkuseddata !== undefined && item.checkmyworkuseddata !== null && item.checkmyworkuseddata !== "" && item.checkmyworkuseddata !== "false" && item.checkmyworkuseddata !== false ?
                                                                                    (<Tooltip title="Feedback Used" placement='top' arrow >
                                                                                        <IconButton onClick={checkmyworkiconhandler}>
                                                                                            <div className={`boardBtnsreview`}>
                                                                                                <img alt='Feedback Used' src={checkWorkIcon} />
                                                                                            </div>
                                                                                        </IconButton>
                                                                                    </Tooltip>) : null
                                                                            }
                                                                            {
                                                                                item.is_answer_correct === true && item.nextstep_used !== undefined && item.nextstep_used !== null && item.nextstep_used !== "" && item.nextstep_used !== "false" && item.nextstep_used !== false ?
                                                                                    (<Tooltip title="Hint: Next Step Used" placement='top' arrow>
                                                                                        <IconButton onClick={showNextStephandler}>
                                                                                            <div className={`boardBtnsreview`}>
                                                                                                <img alt='Hint: Next Step Used' src={ShowNextstepIcon} />
                                                                                            </div>
                                                                                        </IconButton>
                                                                                    </Tooltip>) : null
                                                                            }
                                                                            {
                                                                                item.is_answer_correct === true && item.show_solution_used !== undefined && item.show_solution_used !== null && item.show_solution_used !== "" && item.show_solution_used !== "false" && item.show_solution_used !== false ?
                                                                                    (<Tooltip title="Show Solution Used" placement='top' arrow>
                                                                                        <IconButton onClick={showSholutionhandler}>
                                                                                            <div className={`boardBtnsreview`}>
                                                                                                <img alt='Show Solution Used' src={ShowFullSolutionIcon} />
                                                                                            </div>
                                                                                        </IconButton>
                                                                                    </Tooltip>) : null
                                                                            }


                                                                        </Col>
                                                                    </Row>
                                                                    {
                                                                        contentdatafinal !== undefined && contentdatafinal !== null && contentdatafinal.length > 0 ?
                                                                            (<div className="red" style={{ width: "600px" }}>
                                                                                {contentdatafinal}
                                                                            </div>) : null
                                                                    }
                                                                    {
                                                                        getCurrentUserRole() === User_Role.Student ? (
                                                                            <>
                                                                                {
                                                                                    item.ta_feedback && item.ta_feedback !== undefined && item.ta_feedback !== null ?
                                                                                        (
                                                                                            <div className='mt-4'
                                                                                                style={{ paddingTop: "10px" }}>
                                                                                                <div className="popupSectionTitlemedium">Additional feedback by professor</div>
                                                                                                <div className='feedbackBox commonfeedbackclasswithoutHeight'>{item.ta_feedback}</div>
                                                                                            </div>
                                                                                        ) : null
                                                                                }
                                                                            </>
                                                                        ) : (<div className='feedbackBox'>
                                                                            <span >Additional feedback by professor</span>
                                                                            <textarea rows={3} type='text'
                                                                                value={item.ta_feedback}
                                                                                onChange={(e) => handleFeedbackChangeEvent(e, index)}
                                                                            >
                                                                            </textarea>
                                                                        </div>)

                                                                    }
                                                                    {

                                                                        hintDivVisible ? (
                                                                            <>

                                                                                {
                                                                                    item.is_answer_correct === true && item.hints_used !== undefined && item.hints_used !== "" && item.hints_used.length > 0 ?
                                                                                        <>
                                                                                            <div className='whiteBox'><div className='popupSectionTitlemediumborder' style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                                <div className='popupSectionTitlemedium'> Hints Used</div>
                                                                                                {
                                                                                                    item.isgraded ?
                                                                                                        (
                                                                                                            <>
                                                                                                                <div className='pointsContainer '>
                                                                                                                    {

                                                                                                                        <>
                                                                                                                            <div style={{ textAlign: "end" }} className='d-flex align-items-center justify-content-end'>
                                                                                                                                <span>Hint Points:</span>
                                                                                                                                {
                                                                                                                                    getCurrentUserRole() === User_Role.Student ? (<span style={{ marginLeft: '5px' }}>{item.ta_hint_points}</span>) :
                                                                                                                                        (
                                                                                                                                            <input style={{ marginLeft: '5px' }} name="points" class="form-control bg-yellow" type='text'
                                                                                                                                                onChange={(e) => handleHintPointsChangeEvent(e, index)}
                                                                                                                                                value={item.ta_hint_points}></input>
                                                                                                                                        )
                                                                                                                                }
                                                                                                                            </div>

                                                                                                                        </>

                                                                                                                    }
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                        : null
                                                                                                }
                                                                                            </div>
                                                                                                <div className='tuorporfile-content-card'>
                                                                                                    {
                                                                                                        item.hints_used.map((hint) => {

                                                                                                            let displaystring = ""
                                                                                                            if (hint.remedial_source_title !== "None" && hint.remedial_source_title !== null && hint.remedial_source_title !== undefined && hint.remedial_source_title !== "null" && hint.remedial_source_title !== "undefined") {
                                                                                                                displaystring = hint.remedial_source_title
                                                                                                            }
                                                                                                            else if (hint.remedial_source_url !== "None" && hint.remedial_source_url !== null && hint.remedial_source_url !== undefined && hint.remedial_source_url !== "null" && hint.remedial_source_url !== "undefined") {
                                                                                                                displaystring = hint.remedial_source_url
                                                                                                            }
                                                                                                            return (
                                                                                                                <div className='profile-card-text equationTxtnonfrqsmall'>
                                                                                                                    <Row>
                                                                                                                        <Col>
                                                                                                                            <div className='modal-content' style={{ marginBottom: '10px', borderBottom: '1px solid lightGray' }}>
                                                                                                                                <p className='mb-2' dangerouslySetInnerHTML={{ __html: hint.remedial_text }} />

                                                                                                                                <a href={hint.remedial_source_url} target='_blank' rel="noopener noreferrer">
                                                                                                                                    <p className='mb-2' dangerouslySetInnerHTML={{ __html: displaystring }} />
                                                                                                                                </a>

                                                                                                                            </div>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        : <div className=''></div>
                                                                                }

                                                                            </>
                                                                        ) : null
                                                                    }

                                                                    {
                                                                        checkMyWorkDivVisible ? (
                                                                            <>
                                                                                {
                                                                                    item.checkmyworkuseddata !== undefined && item.checkmyworkuseddata !== "" && item.checkmyworkuseddata !== "[]" && item.checkmyworkuseddata.length > 0 ?

                                                                                        (
                                                                                            <>
                                                                                                <div style={{ maxWidth: "500px", marginTop: "20px" }}>
                                                                                                    <div className='popupSectionTitlemedium' style={{ borderBottom: "1px solid #ccc", paddingBottom: "10px" }}> Feedback Used</div>
                                                                                                    <CheckMyworkResultCNonFRQ checkmyworkuseddata={item.checkmyworkuseddata}></CheckMyworkResultCNonFRQ>
                                                                                                </div>
                                                                                            </>

                                                                                        ) : null

                                                                                }
                                                                            </>
                                                                        ) : null
                                                                    }




                                                                </div>

                                                            </div>
                                                        </>)
                                                })
                                            ) : null
                                    }
                                </>
                            ) : (<div style={{ padding: "20px", fontSize: "18px", margin: "10px" }}>{displayMessag}</div>)
                    }
                </div>
            </div>
            {
                getCurrentUserRole() === User_Role.Student ? (
                    <div className='modal-button text-center'><Button className="grey-btn" clicked={props.showreviewdnonFRQModalclose}> Ok </Button></div>) :
                    (
                        <>
                            {
                                modalData1 !== null && modalData1 !== undefined ?
                                    <div className='modal-button text-center'>
                                        <Button className="yellow-btn" clicked={() => handleUpdate()}> Update </Button>
                                        <span style={{ marginLeft: "10px" }}>
                                            <Button className="grey-btn" clicked={props.showreviewdnonFRQModalclose}> Cancel </Button></span>
                                    </div>
                                    : <div className='modal-button text-center'>
                                        <span style={{ marginLeft: "10px" }}>
                                            <Button className="grey-btn" clicked={props.showreviewdnonFRQModalclose}> Cancel </Button></span>
                                    </div>
                            }
                        </>
                    )
            }

            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }
        </>
    )
}

export default HomeworkReviewDetailNonFRQ 